export const propertyCategories = [
  {
    name: "All Properties",
    _id: "all",
  },
  {
    name: "Own Earner Properties",
    _id: "Own-earner",
  },
  {
    name: "Estate Properties",
    _id: "Estate",
  },
  {
    name: "Non Estate Properties",
    _id: "Non-estate",
  },
  {
    name: "3rd Party Properties",
    _id: "Third-party",
  },
  {
    name: "Shortlets",
    _id: "Shortlet",
  },
];